<template>
  <div class="loading-view">
    <van-overlay :show="loading" z-index="1111111" />
    <van-loading class="loading" color="#fff" v-if="loading" size="24px">{{
        text
    }}</van-loading>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    text: {
      type: String,
      default() {
        return "Connecting...";
      },
    },
  },
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>
<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11111111;
  display: flex;
  flex-direction: column;
  align-items: center;

  .van-loading__text {
    margin-top: 20px;
  }
}
</style>