<template>
  <div class="container">
    <!-- <div class="tip">
            <div class="label">
                我的市场质押量MCC
            </div>
            <div class="value">{{marketamcc}}</div>
        </div>
        <div class="tip">
            <div class="label">
                我的余额
            </div>
            <div class="value">{{balance}}</div>
        </div>
        <div class="tip">
            <div class="label">
                质押总量
            </div>
            <div class="value">{{totalmcc}}</div>
        </div> -->
    <img class="main-image" src="@/assets/images/pledge/1.png" alt="" />
    <div class="form">
      <div class="label">{{ $t("pledge.pledgeQuantity") }}</div>
      <van-field
        v-model="number"
        type="number"
        :placeholder="$t('pledge.placeholder')"
        input-align="center"
        :formatter="formatter"
      />
      <!-- loading start -->
      <loading :loading="is_loading" text="Loading..." />
      <!-- loading end -->
    </div>
    <button class="max-button" v-if="is_buy" type="button" @click="submit">
      {{ $t("common.submit") }}
    </button>
    <button class="max-button disabled" v-else type="button">
      {{ $t("common.submit") }}
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  mccTokenAddress,
  zhiyacollectionAddress,
  getChainId,
  getTokenBalance,
  mccTransferAccounts,
} from "@/common/metaMask/index";
import loading from "@/components/loading";
import { getUserInfo, getHomeData } from "@/request/api";
export default {
  components: { loading },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
    }),
  },
  data() {
    return {
      number: "",
      is_loading: false,
      marketamcc: "",
      balance: "",
      totalmcc: "",
      is_buy: false,
      setI: null,
    };
  },
  mounted() {
    this.get_getUserInfo();
  },
  beforeRouteLeave(to, from, next) {
    next();
    clearInterval(this.setI);
    this.setI = null;
  },
  methods: {
    formatter(value) {
      // 过滤输入的数字
      const _value = value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, "$1");
      return _value;
    },
    handleNumber(v) {
      const value = Number(v.target.value)
        .toFixed(4)
        .replace(/(\d+)(\d{4})\.?/, "$1.$2");
      this.number = Number(value) == 0 ? "" : Number(value);
    },
    /**
     * 获取用户信息
     */
    get_getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.marketamcc = res.data.marketamcc;
          this.balance = res.data.balance;
          this.totalmcc = res.data.totalmcc;
        } else {
          this.$toast.fail(res.msg);
        }
      });
      this.setI = setInterval(() => {
        getHomeData().then((res) => {
          if (res.code == 200) {
            this.is_buy = res.data.endTime <= 0;
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }, 1000);
    },
    /**
     * 提交
     */
    submit() {
      if (this.number == "") {
        this.$notify({
          type: "warning",
          message: this.$t("pledge.placeholder"),
        });
        return;
      }
      this.is_loading = true;
      getChainId().then((res) => {
        if (parseInt(res) != 56) {
          this.is_loading = false;
          this.$notify({
            type: "warning",
            message: this.$t("warning.chainError"),
          });
          return;
        }
        getTokenBalance(this.walletAddress, mccTokenAddress, "mcc").then(
          (res) => {
            console.log(res);
            console.log(this.number);
            if (Number(res) < Number(this.number)) {
              this.is_loading = false;
              this.$notify({
                type: "warning",
                message: this.$t("warning.balance"),
              });
              return;
            }
            mccTransferAccounts(
              this.walletAddress,
              mccTokenAddress,
              zhiyacollectionAddress,
              Number(this.number)
            )
              .then((txid) => {
                this.is_loading = false;
                if (txid) {
                  this.$notify({
                    type: "success",
                    message: this.$t("warning.paymentSuccessful"),
                  });
                } else {
                  this.$notify({
                    type: "danger",
                    message: this.$t("warning.paymentFailed"),
                  });
                }
              })
              .catch((error) => {
                this.is_loading = false;
                this.$notify({
                  type: "danger",
                  message: this.$t("warning.paymentFailed"),
                });
              });
          }
        );
      });
    },
    routerPush(path) {
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .tip {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #77d63c;
    padding-bottom: 10px;

    .value {
      font-weight: bold;
      padding-left: 20px;
      font-size: 36px;
    }
  }

  .main-image {
    width: 480px;
    display: block;
    margin: 20px auto 27px;
  }

  .form {
    .label {
      margin-bottom: 37px;
      text-align: center;
      font-size: 36px;
      font-weight: 500;
      color: #59695f;
    }
  }

  .max-button {
    margin-top: 72px;

    &.disabled {
      background: #ccc;
    }
  }
}
</style>
